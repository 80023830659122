<script>
import AddBtn from "@/components/buttons/AddBtn.vue";
import Tooltip2Vue from "@/components/Tooltip2Vue/Tooltip2Vue";
import LegoTooltip from "@/components/Lego/LegoTooltip.vue";
import LegoCheck from "@/components/Lego/LegoCheck.vue";
import LegoProgressBar from "@/components/Lego/LegoProgressBar.vue";
import draggable from "vuedraggable";
export default {
  props: {
    color: {
      type: String,
      default: "#ef476f",
    },
  },
  components: {
    AddBtn,
    Tooltip2Vue,
    LegoTooltip,
    LegoCheck,
    LegoProgressBar,
    draggable,
  },
  data() {
    return {
      tooltipTitle: "",
      itemInChecklist: "",
      checklistIdShowNewItem: "",
      buttonsDisabled: [],
    };
  },
  mounted() {
    this.resizeAll();
  },
  updated() {
    this.resizeAll();
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "checklistItems",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    checklists() {
      return this.$store.state.lego.card.checklists;
    },
    cardId() {
      return this.$route.params.cardId;
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
  },
  methods: {
    async onCheckListItemsReorder(checklistItems) {
      console.log("checklistItems", checklistItems);

      const reorderItems = checklistItems?.map((item, index) => ({
        ...item,
        orderCheck: index + 1,
      }));

      console.log("reorderItems", reorderItems);

      await this.$store.dispatch("lego/reorderChecklistItems", reorderItems);
    },
    focusOnTooltipTitle() {
      this.$nextTick(() => {
        const input = document.querySelector("#tooltipTitle");
        input.focus();
      });
    },
    async addChecklist(close) {
      const title = this.tooltipTitle.trim();
      if (!title) return;

      const checklist = {
        legoCardId: this.cardId,
        title,
        createdById: this.userLoggedIn.id,
      };

      await this.$store.dispatch("lego/addChecklist", checklist);

      const lastId = this.checklists[this.checklists.length - 1].id;

      this.closeTooltip(close);
      this.checklistIdShowNewItem = lastId;
      this.toFocusNewItemInChecklist();
    },
    closeTooltip(close) {
      this.tooltipTitle = "";
      close();
    },
    addItemInChecklist() {
      const title = this.itemInChecklist.trim();
      if (!title) return;

      const currentChecklist = this.checklists.find(
        (cl) => cl.id === this.checklistIdShowNewItem
      );

      console.log("currentChecklist", currentChecklist.items);

      const item = {
        legoChecklistId: this.checklistIdShowNewItem,
        title,
        createdById: this.userLoggedIn.id,
        orderCheck: currentChecklist.items.length + 1,
      };

      this.$store.dispatch("lego/addItemInChecklist", item);

      this.itemInChecklist = "";
    },
    addItemInChecklistCtrlEnter(ev) {
      if (ev.ctrlKey && ev.keyCode == 13) {
        this.addItemInChecklist();
      }
    },

    closeControlsInItemInChecklist() {
      this.checklistIdShowNewItem = "";
      this.itemInChecklist = "";
    },
    toFocusNewItemInChecklist(id = null) {
      this.checklistIdShowNewItem = id ? id : this.checklistIdShowNewItem;
      this.$nextTick(() => {
        const input = this.$el.querySelector(`#inputNewItemInChecklist`);
        input.focus();
      });
    },

    deleteItemInChecklist(ev, item) {
      ev.target.innerHTML =
        '<span><i class="fas fa-spinner fa-spin text-lego-danger"></i></span>';
      this.$store.dispatch("lego/deleteItemInChecklist", item);
    },
    resizeTextarea(ev) {
      ev.target.style.height = "auto";
      ev.target.style.height = ev.target.scrollHeight + "px";
    },
    resizeAll() {
      if (Object.keys(this.$el).length == 1) return;
      const textareas = this.$el.querySelectorAll(".text-area-item");
      textareas.forEach((textarea) => {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
      });
    },
    async setDoneItem(item) {
      const newItem = {
        id: item.id,
        isDone: !item.isDone,
        doneBy: this.userLoggedIn.id,
      };

      this.buttonsDisabled.push(newItem.id);
      await this.$store.dispatch("lego/SetIsDoneItemInChecklist", newItem);
      this.buttonsDisabled = this.buttonsDisabled.filter(
        (id) => id != newItem.id
      );
    },
    isDisabled(item) {
      return this.buttonsDisabled.includes(item.id);
    },
    updateItemTitle(ev) {
      this.resizeTextarea(ev);
    },
    saveTitleItem(ev, item) {
      const title = ev.target.value.trim();
      if (!title || title == item.title) return;
      const newItem = { ...item };
      newItem.title = title;

      this.$store.dispatch("lego/updateItemInChecklist", newItem);

      this.focusId = null;
    },
    saveTitleItemCtrlEnter(ev, item) {
      if (ev.ctrlKey && ev.keyCode == 13) {
        this.saveTitleItem(ev, item);
        ev.target.blur();
      }
    },
    deleteChecklist(ev, checklist) {
      ev.target.innerHTML =
        '<span><i class="fas fa-spinner fa-spin text-lego-danger"></i></span>';
      this.$store.dispatch("lego/deleteChecklist", checklist);
    },
    saveTitleChecklist(ev, checklist) {
      const newTitle = ev.target.value.trim();
      ev.target.blur();

      this.$store.dispatch("lego/renameChecklist", {
        id: checklist.id,
        oldTitle: checklist.title,
        newTitle,
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="item-display">
      <div>
        <span>
          <i class="fa-regular fa-circle-check title-icon"></i>
          <!-- <i class="fas fa-list-check title-icon"></i> -->
        </span>
      </div>
      <div>
        <span class="title-section"> Checklists </span>
      </div>

      <LegoProgressBar
        class="in-right"
        :list="checklists?.map((c) => c.items).flat()"
        :color="color"
      />

      <Tooltip2Vue position="bottom" class="in-right">
        <div class="btn-shadow" @click="focusOnTooltipTitle">
          <AddBtn />
          <span>Nuevo Checklist</span>
        </div>
        <template #tooltip="{ close }">
          <div class="tp-lego-container">
            <label for="tooltipTitle">Titulo</label>
            <input
              v-model="tooltipTitle"
              id="tooltipTitle"
              type="text"
              class="lego-input"
              @keydown.enter="addChecklist(close)"
            />
            <div class="tp-controls">
              <vs-button @click="addChecklist(close)"
                >Crear Checklist</vs-button
              >
              <vs-button danger @click="closeTooltip(close)">Cerrar</vs-button>
            </div>
          </div>
        </template>
      </Tooltip2Vue>
    </div>

    <div v-for="checklist in checklists" :key="checklist.id" class="checklist">
      <div class="item-display">
        <div>
          <span>
            <i class="fas fa-list-check title-icon title-box"></i>
          </span>
        </div>
        <header class="header-checklist">
          <input
            type="text"
            class="title lego lego-input title-box"
            @blur="($event) => saveTitleChecklist($event, checklist)"
            @keyup.enter="($event) => saveTitleChecklist($event, checklist)"
            :value="checklist.title"
          />

          <LegoTooltip class="cancel-default-styles trash-icon-checklist">
            <template #button>
              <span>
                <i class="fa-regular fa-trash-alt title-icon"></i>
              </span>
            </template>
            <div>
              <p>
                <strong>Eliminar {{ checklist.title }}</strong>
              </p>
              <p>
                Eliminar un checklist es una acción permanente y no se podrá
                recuperar
              </p>
              <div
                @click="deleteChecklist($event, checklist)"
                class="btn-delete-checklist py-2"
              >
                <span class="text-lego-danger">Eliminar</span>
              </div>
            </div>
          </LegoTooltip>
        </header>
        <LegoProgressBar
          class="in-right"
          :list="checklist.items"
          :color="color"
        />
      </div>

      <!-- draggable checklist -->
      <draggable
        v-model="checklist.items"
        group="checklist.items"
        class="body"
        @end="onCheckListItemsReorder(checklist.items)"
        :options="dragOptions"
      >
        <div
          v-for="item in checklist.items"
          :key="item.id"
          class="item-display item-in-checklist"
        >
          <LegoCheck
            class="btn-checked"
            :value="item.isDone"
            @input="setDoneItem(item)"
            :waiting="isDisabled(item)"
          />

          <div class="item-in-checklist--right">
            <textarea
              class="lego-input text-area-item"
              rows="1"
              @focus="focusId = item.id"
              @keydown.enter="saveTitleItemCtrlEnter($event, item)"
              @blur="saveTitleItem($event, item)"
              @input="updateItemTitle($event)"
              :value="item.title"
            ></textarea>
            <div class="checklistItemControls">
              <LegoTooltip position="top" class="z-more">
                <span
                  @click="deleteItemInChecklist($event, item)"
                  class="text-lego-danger"
                  >Eliminar item</span
                >
              </LegoTooltip>
            </div>
          </div>
        </div>
      </draggable>

      <footer class="item-display">
        <div class="in-right checklist-add-item">
          <button
            @click="toFocusNewItemInChecklist(checklist.id)"
            class="btn-shadow"
            v-if="checklistIdShowNewItem != checklist.id"
          >
            <AddBtn style="height: auto" />
            <span>Nuevo item</span>
          </button>
          <div v-else>
            <textarea
              id="inputNewItemInChecklist"
              class="lego-input textarea-item"
              placeholder="¿De que trata este paso este paso?"
              @keydown.esc="closeControlsInItemInChecklist"
              @keydown.enter="addItemInChecklistCtrlEnter"
              @input="resizeTextarea($event)"
              v-model="itemInChecklist"
            ></textarea>
            <div class="checklist-add-item--controls">
              <vs-button @click="addItemInChecklist">Crear Checklist</vs-button>
              <vs-button danger @click="closeControlsInItemInChecklist"
                >Cerrar</vs-button
              >
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<style scoped>
.title {
  border: none;
  font-size: 1.1rem;
  color: white;
  margin-bottom: 0.8rem;
  padding: 0.2rem 0 0rem 0.5rem;
  border-radius: 0px;
  margin-right: 1rem;
  margin-bottom: 0;
}

.title-box {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.title.lego:focus {
  border: none;
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.5);
}

.item-display {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0.5rem;
}

.span-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.in-right {
  grid-column-start: 2;
}

.title-section,
.title-icon {
  font-size: 1.1rem;
  display: flex;
  gap: 0.5rem;
}

.title-icon {
  font-size: 1.2rem;
}

.btn-shadow {
  padding: 0.25rem 0.5rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
}

.tp-controls {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 0.5rem;
}

.tp-controls > * {
  margin: 0;
}

.checklist {
  margin: 2rem 0 1rem;
}

.header-checklist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 0.5rem; */
}

.trash-icon-checklist {
  opacity: 0;
  transition: 0.3s;
}
.header-checklist:hover .trash-icon-checklist {
  opacity: 1;
}

.checklist-add-item {
  margin-top: 0.8rem;
}

.textarea-item {
  font-size: 0.9rem;
  min-height: auto;
}

.checklist-add-item--controls {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 0.5rem;
}

.checklist-add-item--controls > * {
  margin: 0;
}

.item-in-checklist {
  padding: 0.5rem;
  transform: scale(1.02);
  margin: 1rem 0;
  cursor: grab;
  background-color: var(--lego-dbg);
  .dark & {
    background-color: var(--lego-bg);
  }
}

.item-in-checklist:last-child {
  margin-bottom: 0.2rem;
}

.item-in-checklist--right {
  display: flex;
  gap: 0.5rem;
}

.item-in-checklist--right textarea {
  border: none;
  min-height: auto;
  font-size: 0.9rem;
  padding: 0.2rem 0;
}

.item-in-checklist--right textarea:focus {
  padding: 0.2rem 0.4rem;
}

.btn-checked {
  height: 21px;
  width: 21px;
}

.checklistItemControls {
  transition: 0.3s;
  opacity: 0;
}

.item-in-checklist:hover .checklistItemControls {
  opacity: 1;
}

.btn-delete-checklist {
  transition: 0.3s;
  cursor: pointer;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 8px;
}

.btn-delete-checklist:hover {
  box-shadow: 0 0 0 2px var(--lego-danger);
}
</style>
