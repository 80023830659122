<script>
import LegoQuill from "@/components/Lego/LegoQuill.vue";
import LegoDropzone from "@/components/Lego/LegoDropzone.vue";

export default {
  components: {
    LegoQuill,
    LegoDropzone,
  },
  data() {
    return {
      commentHtml: "",
      loaderFile: false,
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    cardId() {
      return this.$route.params.cardId;
    },
    comments() {
      console.log(this.$store.state.lego.card.comments);

      return this.$store.state.lego.card.comments?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    },
  },
  methods: {
    cancelComentario(blurQuill) {
      this.commentHtml = "";
      blurQuill();
    },
    keydownHandler(ev) {
      if (ev.ctrlKey && ev.keyCode == 13) {
        this.sendComment();
      }
    },
    sendComment() {
      const comment = {
        legoCardId: this.cardId,
        html: this.commentHtml,
        createdById: this.userLoggedIn.id,
      };

      this.$store.dispatch("lego/addCommentInCard", comment);

      // this.comments.push(comment)
      this.commentHtml = "";
    },
    tiempoTranscurrido(date) {
      return this.$store.getters.tiempoTranscurrido(date, true);
    },
    async insertImageAsLink(self) {
      this.loaderFile = true;
      await self.saveInSmarter();

      if (self.isSaveFile) {
        let aImgHtml;
        if (self.file.type.includes("image")) {
          aImgHtml = `<div>
            <a target="_blank" href="${self.pathAfterSaveInRemote}" >
            <img width="200" src="${self.pathAfterSaveInRemote}" alt="${
            self.fileName + self.dotExtension
          }" />
            </a>
          </div>`;
        } else {
          aImgHtml = `<p>
            <a target="_blank" href="${self.pathAfterSaveInRemote}" >
            ${self.fileName + self.dotExtension}
            </a>
          </p>`;
        }
        console.log("aImgHtml", aImgHtml);

        this.commentHtml += aImgHtml;
      } else {
        console.log("error");
      }

      this.loaderFile = false;
    },
  },
};
</script>

<template>
  <div>
    <header class="item-display header">
      <div>
        <span>
          <i class="fa-regular fa-comment-dots title-icon"></i>
          <!-- <i class="fas fa-list-check title-icon"></i> -->
        </span>
      </div>
      <div>
        <span class="title-section"> Comentarios </span>
      </div>
    </header>

    <article class="item-display">
      <div class="in-right">
        <LegoQuill
          class="quill-container"
          v-model="commentHtml"
          @ctrlEnter="sendComment"
          @esc="cancelComentario"
          :placeholder="'Tu comentario\nEnviar: ctrl + enter'"
        >
          <template #header>
            <div class="inject-header">
              <!-- En el componente debemos meter un calback solo cuando se suben los archivos -->
              <LegoDropzone hiddenFooter @fileInCache="insertImageAsLink">
                <template #dropzone="{ triggerFileInput }">
                  <div
                    class="dropzone quill-dropzone"
                    @click="triggerFileInput"
                  >
                    <!-- documentos -->
                    <i v-if="!loaderFile" class="fas fa-file-upload"></i>
                    <i v-else class="fa fa-spinner fa-spin"></i>
                  </div>
                </template>
              </LegoDropzone>
            </div>
          </template>

          <template #controls="{ blurQuill }">
            <vs-button @click="sendComment">Enviar</vs-button>
            <vs-button danger @click="cancelComentario(blurQuill)"
              >Cancelar</vs-button
            >
          </template>
        </LegoQuill>
      </div>
    </article>

    <article>
      <div
        v-for="comment in comments"
        :key="comment.id"
        class="item-display comment-content"
      >
        <div class="item-display-figure">
          <img
            v-if="comment.createdBy.imagenPerfilUrl"
            :src="comment.createdBy.imagenPerfilUrl"
            alt="imagen-perfil"
          />
          <i v-else class="fas fa-user"></i>
        </div>
        <div class="comment-body--creator">
          <span
            >{{ comment.createdBy.nombres }}
            {{ comment.createdBy.apellidos }}</span
          >
        </div>
        <div class="comment-body in-right">
          <div class="commentHtml" v-html="comment.html"></div>
          <div class="comment-date">
            <i class="far fa-comment mr-1"></i>
            <span
              v-b-tooltip
              :title="$moment(comment.createdAt).format('DD MMM, YYYY hh:mm a')"
              >{{ comment.createdAt | moment("from", "now") }}</span
            >
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<style scoped>
.quill-container {
  position: relative;
}

.inject-header {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.5rem;
}
.quill-dropzone {
  z-index: 1;
  height: 25px;
  width: 25px;
}

.header {
  margin-bottom: 0.8rem;
}

.item-display {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0.5rem;
}

.in-right {
  grid-column-start: 2;
  grid-column-end: none;
}

.title-section,
.title-icon {
  font-size: 1.1rem;
  display: flex;
  gap: 0.5rem;
}

.title-icon {
  font-size: 1.2rem;
}

.controls {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.8rem;
}

.controls > * {
  margin: 0;
}

.item-display-figure {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-display-figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.comment-body--creator {
  height: 24px;
  display: flex;
  align-items: center;
}

.comment-content {
  margin: 1rem 0;
}

.comment-body {
  border-radius: 5px;
  padding: 0.5rem;
  background-color: var(--lego-dbg);

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  .dark & {
    background-color: var(--lego-bg);
  }
}

.comment-date {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tc-enter-active,
.tc-leave-active {
  transition: all 0.5s ease-out;
  height: 28px;
}

.tc-enter,
.tc-leave-to {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.commentHtml :deep(p) {
  margin: 0.2rem;
}

.commentHtml :deep(ol),
.commentHtml :deep(ul) {
  margin: 0.2rem 0;
  padding-left: 1rem !important;
}

.commentHtml :deep(ul li) {
  list-style: circle;
}
.commentHtml :deep(ol li) {
  /* le pone un decorador a cada elemento en la lista */
  list-style: number;
}
</style>
