<script>
import AddBtn from "@/components/buttons/AddBtn.vue";
import Tooltip2Vue from "@/components/Tooltip2Vue/Tooltip2Vue";
import CardInColumn from "@/components/Lego/CardInColumn.vue";
import LegoProgressBar from "@/components/Lego//LegoProgressBar.vue";
export default {
  components: {
    Tooltip2Vue,
    AddBtn,
    CardInColumn,
    LegoProgressBar,
  },
  props: {
    color: {
      type: String,
      default: "#ef476f",
    },
  },
  data() {
    return {
      title: "",
    };
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn;
    },
    cardId() {
      return this.$route.params.cardId;
    },
    dependents() {
      return this.$store.state.lego.card.dependents;
    },
  },
  methods: {
    focusOnTooltipTitle() {
      this.$nextTick(() => {
        const input = document.querySelector("#tooltipTitle");
        input.focus();
      });
    },

    createCard() {
      const title = this.title.trim();
      if (title === "") return;

      const card = {
        title,
        LegoCardId: this.cardId,
        createdBy: this.userLoggedIn.id,
      };

      this.$store.dispatch("lego/addSubCard", card);
      this.title = "";
    },
    closeTooltip(close) {
      this.title = "";
      close();
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <header class="item-display header">
      <div>
        <span>
          <i class="fas fa-layer-group title-icon"></i>
        </span>
      </div>
      <div>
        <span class="title-section"> Sub tareas </span>
      </div>
    </header>
    <div class="item-display">
      <LegoProgressBar :list="dependents" class="in-right" :color="color" />
      <Tooltip2Vue position="bottom" class="in-right">
        <div class="btn-shadow" @click="focusOnTooltipTitle">
          <AddBtn />
          <span>Nueva sub tarea</span>
        </div>
        <template #tooltip="{ close }">
          <div class="tp-lego-container">
            <label for="tooltipTitle">Titulo</label>
            <input
              v-model="title"
              id="tooltipTitle"
              type="text"
              class="lego-input"
              @keydown.enter="createCard"
            />
            <div class="tp-controls">
              <vs-button @click="createCard">Crear sub tarea</vs-button>
              <vs-button danger @click="closeTooltip(close)">Cerrar</vs-button>
            </div>
          </div>
        </template>
      </Tooltip2Vue>
    </div>
    <div class="subCards">
      <CardInColumn
        :disabledControls="true"
        class="resumenSubCard"
        v-for="subCard in dependents"
        :key="subCard.id"
        :card="subCard"
        :id="subCard.id"
      />
    </div>
  </div>
</template>

<style scoped>
.header {
  margin-bottom: 0.8rem;
}

.in-right {
  grid-column-start: 2;
}

.item-display {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0.5rem;
}

.title-section,
.title-icon {
  font-size: 1.1rem;
  display: flex;
  gap: 0.5rem;
}

.title-icon {
  font-size: 1.2rem;
}

.in-right {
  grid-column-start: 2;
}

.btn-shadow {
  padding: 0.25rem 0.5rem;
  border: solid 1px rgba(255, 255, 255, 0.25);
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.9rem;
}

.tp-controls {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  gap: 0.5rem;
}

.tp-controls > * {
  margin: 0;
}

.subCards {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.resumenSubCard {
  background: var(--lego-dbg);
  .dark & {
    background: var(--lego-bg);
  }
  padding: 0.5rem 0.5rem 0.5rem 35px;
}
</style>
